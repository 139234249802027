<template>
  <v-main>
    <v-card outlined width="500px" class="center">
      <v-card-text>
        <v-form ref="form">
          <v-card-title>Reset password </v-card-title>

          <v-card-text>
            <v-text-field
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              outlined
              dense
              name="name"
              label="New password"
              v-model="password"
              :rules="[v => !!v || 'Password is required']"
            ></v-text-field>

            <v-text-field
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              @click:append="show3 = !show3"
              outlined
              dense
              name="name"
              label="Confirm password"
              v-model="confirmPassword"
              :rules="[
                v => !!v || 'Confirm password is required',
                v => v == password || 'Confirm password does not match',
              ]"
            ></v-text-field>

            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6">
                <v-btn block color="primary" :loading="loading" @click="resetPassword()">Reset password</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import { AuthService } from '@services';

export default {
  data() {
    return {
      loading: false,
      show2: false,
      show3: false,
      password: null,
      confirmPassword: null,
    };
  },

  methods: {
    async resetPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const response = await AuthService.resetPassword({ token: this.$route.query.token, password: this.password });
        if (response) {
          this.$emit({ name: 'promoter.login' });
        }

        this.loading = false;
      }
    },
  },
};
</script>
